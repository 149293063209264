import React, { Fragment } from "react";
import * as Icon from "./Icon";
import ReactPlayer from "react-player";
import styled from "styled-components";

export default class Player extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: false,
    };
  }

  render() {
    let { url } = this.props;
    let { playing } = this.state;
    return (
      <Fragment>
        <ReactPlayer
          url={url}
          width="100%"
          height="100%"
          playing={playing}
          className="react-player"
          // playIcon={<Icon.PlayCircleOutline color="white" size={17} />}
          playsinline={true}
          controls={true}
          onReady={() => this._postMessage("ready")}
          onEnded={() => this._postMessage("ended")}
          onPlay={() => this._postMessage("play")}
          onStart={() => this._postMessage("start")}
          onPause={() => this._postMessage("pause")}
        />
        {/* <PlayBack onClick={this._onToggle} playing={playing}/> */}
      </Fragment>
    );
  }

  _postMessage = eventName => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(`${eventName}`);
    }
  };

  _onToggle = () => {
    this.setState({ playing: !this.state.playing });
  };
}

const PlayBack = ({ onClick, playing }) => (
  <Center style={{ padding: 20 }} onClick={onClick}>
    {!playing && <Icon.PlayCircleOutline color="#fffd" size={50} />}
  </Center>
);

const Center = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
