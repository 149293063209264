import React from "react";
import styled from "styled-components";
import Image from "./Image";
import CmsView from "./CmsView";
import Constants from "../constants";
import ReactPlayer from "react-player";

const TABS = {
  INTRO: 0,
  DATA: 1,
  BULLETIN: 2,
};
class ProductBottomTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: TABS.INTRO,
    };
  }

  render() {
    const { tabIndex } = this.state;
    let { video } = this.props;

    return (
      <Wrapper>
        <TabBar>
          <TabItem
            selected={tabIndex === TABS.INTRO}
            onClick={() => this.setState({ tabIndex: 0 })}
          >
            章節介紹
          </TabItem>
          <TabItem
            selected={tabIndex === TABS.DATA}
            onClick={() => this.setState({ tabIndex: 1 })}
          >
            講義下載
          </TabItem>
          <TabItem
            selected={tabIndex === TABS.BULLETIN}
            onClick={() => this.setState({ tabIndex: 2 })}
          >
            課程公告
          </TabItem>
        </TabBar>

        <TabContent>
          {tabIndex === TABS.INTRO && (
            <div className="image-container">
              <CmsView cmsContent={video.intro} />
            </div>
          )}

          {tabIndex === TABS.DATA && (
            <div className="image-container">
              {(() => {
                if (video.handouts.length < 1) {
                  return null;
                } else {
                  return video.handouts.map((handout, idx) => {
                    return (
                      <div style={{ marginTop: 30 }} key={idx}>
                        <p>{handout.name}</p>
                        <a
                          style={{
                            color: "#009688",
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={e => {
                            e.stopPropagation();
                            this._downloadFile({
                              url: `${Constants.mediaHost}${eval(
                                'encodeURI("' + handout.file + '")'
                              )}`,
                              fileName: handout.file.split("/").slice(-1),
                            });
                          }}
                        >
                          {handout.file.split("/").slice(-1)}
                        </a>
                      </div>
                    );
                  });
                }
              })()}
            </div>
          )}

          {tabIndex === TABS.BULLETIN && (
            <div className="image-container">
              <CmsView cmsContent={video.bulletin} />
            </div>
          )}
        </TabContent>
      </Wrapper>
    );
  }

  _downloadFile = async ({ url, fileName }) => {
    let resp = await fetch(url);
    let blob = await resp.blob();
    let objUrl = URL.createObjectURL(blob);

    var a = document.createElement("a");
    document.body.appendChild(a);
    a.id = "rev-download-url";
    a.style = "display: none";
    a.href = objUrl;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(objUrl);
    document.body.removeChild(a);
  };
}

const Wrapper = styled.div`
  /* border: 1px solid #ddd; */
`;

const TabBar = styled.div`
  display: flex;
`;

const TabItem = styled.div`
  border-radius: 10px 10px 0px 0px;
  padding: 10px;
  flex: 1;
  text-align: center;
  color: gray;
  background-color: ${props => (props.selected ? "white" : "#ccc")};
`;

const TabContent = styled.div`
  background-color: white;
  border-radius: 0px 0px 10px 10px;
  min-height: 200px;
  padding: 20px;

  .video-container {
    width: 560px;
    height: 315px;
    margin: 0 auto;

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
  .image-container {
    margin: 0px 100px;
    width: 100% - 200px;

    @media screen and (max-width: 600px) {
      margin: 0px;
    }
  }
`;

export default ProductBottomTabs;
