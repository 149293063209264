import React, { Component } from "react";
import styled from "styled-components";
import Constants from "../constants";

class Image extends Component {
  render() {
    let { src, host, css, background, linkto } = this.props;
    if (linkto) {
      return (
        <a href={(linkto && linkto) || ""} target="_blank">
          <Img
            css={css}
            {...this.props}
            src={this._getFullImageUrl(src, host)}
          />
        </a>
      );
    } else {
      return (
        <Img css={css} {...this.props} src={this._getFullImageUrl(src, host)} />
      );
    }
  }

  _getFullImageUrl = (src, host) => {
    console.log("");
    return `${Constants.mediaHost}${src}`;
  };
}

Image.defaultProps = {
  host: Constants.mediaHost,
  background: false,
  css: "",
};

const Img = styled.img`
  ${props => props.css}
`;

export default Image;
