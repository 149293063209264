import React, { useState } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Selectors from "../Selectors";
import { withPage } from "../PageContainer";
import * as Catalog from "../Contexts/CatalogContext";
import * as Cart from "../Contexts/CartContext";
import * as Ant from "antd";
import * as Theme from "../Theme";
import VideoCourseBottomTabs from "../Components/VideoCourseBottomTabs";
import * as AppEvents from "../AppEvents";
import ActionCreator from "../ActionCreator";
import Image from "../Components/ImageBackground";
import * as Icon from "../Components/Icon";
import Constants from "../constants.js";
import Player from "../Components/Player";
const queryString = require("query-string");

function ChapterDetail(props) {
  const { chapter } = props;
  const [expand, setExpand] = useState(false);

  function toggleExpand() {
    setExpand(!expand);
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          padding: 15,
          borderBottom: "1px solid #ccc",
          cursor: "pointer",
        }}
        onClick={toggleExpand}
      >
        <div>{chapter.name}</div>
        <div style={{ flex: 1 }} />
        <div>{expand ? "-" : "+"}</div>
      </div>

      {expand &&
        chapter.videos.map((video, idx) => (
          <div
            key={idx}
            style={{
              padding: "15px 15px 15px 30px",
              borderBottom: "1px solid #ccc",
              cursor: "pointer",
            }}
            onClick={() => props.onVideoClick(video)}
          >
            {video.name}
          </div>
        ))}
    </>
  );
}

function CourseDetail(props) {
  const { course, chapters } = props;
  return (
    <div>
      {chapters
        .sort((a, b) => a.priority - b.priority)
        .map((ch, idx) => (
          <ChapterDetail key={idx} chapter={ch} {...props} />
        ))}
    </div>
  );
}

function getVideoId(video) {
  const query = "?" + video.video_link.split("?")[1];
  const parsed = queryString.parse(query);
  return parsed.v;
}

class VideoListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      course: null,
      chapters: [],
      mounted: false,
      showOverlay: false,
      selectedVideo: null,
    };
  }

  componentDidMount() {
    this._fetchVideoAndProduct();
  }

  componentDidUpdate(prevProps) {
    let { profile } = this.props;
    if (prevProps.profile !== profile) {
      this._fetchVideoAndProduct();
    }
  }

  render() {
    const {
      mounted,
      loading,
      course,
      chapters,
      showOverlay,
      selectedVideo,
    } = this.state;

    if (loading || !mounted || !course) {
      return <Ant.Spin style={{ margin: "30px auto" }} />;
    }

    const playerSize = this._measureLayout();
    console.log("course>>>", course);
    return (
      <Wrapper>
        <h2>{course.name}</h2>
        <PlayerWrapper
          size={playerSize}
          bkimg={`${Constants.mediaHost}${course.video_course_image}`}
        >
          {selectedVideo && (
            // <iframe
            //   width={playerSize.width}
            //   height={playerSize.height}
            //   title={`YouTube Player for content video`}
            //   src={`${selectedVideo.video_link}`}
            // />
            <Player url={selectedVideo.video_link} />
          )}
        </PlayerWrapper>

        <div
          className="menu-icon"
          onClick={() => this.setState({ showOverlay: true })}
        >
          <Icon.Menu size={20} color={"black"} />
        </div>

        <Ant.Drawer
          title="課程章節"
          placement={"right"}
          width={320}
          closable={false}
          onClose={() => this.setState({ showOverlay: false })}
          visible={showOverlay}
          key={"right"}
        >
          <CourseDetail
            course={course}
            chapters={chapters}
            onVideoClick={this._onVideoClick}
          />
        </Ant.Drawer>

        <div
          className="center-content"
          style={{ paddingTop: 20, paddingBottom: 20 }}
        >
          {selectedVideo && <VideoCourseBottomTabs video={selectedVideo} />}
        </div>
      </Wrapper>
    );
  }

  _fetchVideoAndProduct = async () => {
    this.setState({ mounted: true });
    this.setState({ loading: true });
    try {
      const { location } = this.props;
      const parsed = queryString.parse(location.search);
      let resp;
      resp = await Catalog.Actions.fetchVideos(parsed.id);
      this.setState({ chapters: resp.chapters });
      resp = await Catalog.Actions.fetchProduct(parsed.id);
      this.setState({ course: resp });
    } catch (ex) {
      console.warn(ex);
    }
    this.setState({ loading: false });
  };

  _onVideoClick = video => {
    this.setState({
      selectedVideo: video,
      showOverlay: false,
    });
  };

  _measureLayout = () => {
    const width = window.innerWidth;
    if (width > 1200) {
      return {
        width: 1024,
        height: (1024 / 16) * 9,
      };
    } else if (width > 800) {
      return {
        width: 720,
        height: (720 / 16) * 9,
      };
    } else {
      return {
        width: 320,
        height: (320 / 16) * 9,
      };
    }
  };
}

const Wrapper = styled.div`
  padding-top: 20px;
  background-color: #f7f7f7;
  position: relative;

  & > h2 {
    text-align: center;
    color: ${Theme.colors.main};
  }

  & .menu-icon {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .center-content {
    max-width: 1024px;
    margin: 0px auto;

    & label {
      width: 100px;
      font-weight: bold;
      color: #999;
    }

    & .description {
      margin: 30px 100px;
      width: 100% - 200px;

      @media screen and (max-width: 600px) {
        margin: 0px;
      }
    }
  }
`;

const PlayerWrapper = styled.div`
  width: ${props => props.size.width}px;
  height: ${props => props.size.height}px;
  background-image: url(${props => props.bkimg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #e0e0e0;
  background-color: #bdbdbd;
  margin: 10px auto;
`;

export default withPage(
  Catalog.withConsumer(
    connect(
      (state, ownProps) => ({
        profile: Selectors.getLoginUser(state),
        queryParams: Selectors.getQueryParams(ownProps),
      }),
      ActionCreator
    )(VideoListPage)
  )
);
