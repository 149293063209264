import React, { Component } from "react";
import styled from "styled-components";
import Constants from "../constants.js";

class ImageBackground extends Component {
  render() {
    let { src, extraStyle = {} } = this.props;
    let isLocalImg = false;
    if (src && src.charAt(0) == ".") {
      isLocalImg = true;
    }
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundImage: isLocalImg
            ? `url(${src})`
            : `url(${Constants.mediaHost}${src})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          ...extraStyle,
        }}
        onLoad={this._imgOnLoad}
        onError={this._imgOnError}
        src={`${src}`}
        {...this.props}
        // src={`${Constants.mediaHost}${src}`}
      />
    );
  }

  _imgOnLoad = e => {};

  _imgOnError = e => {
    console.warn("ImageBackground onError", e);
  };
}

export default ImageBackground;
